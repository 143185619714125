import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import {
  Order,
  Orders,
  Products,
  Product,
  ProductFiltered,
} from '../components/pages';

export default function Pages() {
  return (
    <Router basepath="/">
      <Products path="/products/:page" />
      <ProductFiltered path="/products/cat/:page" />
      <Products path="/search/:page" isSearch />
      <Product path="/product/:id" />
      <Order path="/order/:id" />
      <Orders path="/orders" />
    </Router>
  );
}
